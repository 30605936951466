var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Information for "+_vm._s(_vm.$route.params.id)+" block ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.blockInformation),function(value,name,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',[_c('v-list-item-content',_vm._g(_vm._b({attrs:{"two-line":""}},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.blockHeaders[index])+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(value)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltips[index]))])])],1)}),1)],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Transactions for block "+_vm._s(_vm.$route.params.id)+" "),_c('v-text-field',{staticClass:"px-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Show Only Contracts"},on:{"click":function($event){return _vm.showOnlyContracts()}},model:{value:(_vm.contractSwitch),callback:function ($$v) {_vm.contractSwitch=$$v},expression:"contractSwitch"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","show-expand":"","single-expand":"","item-key":"hash","headers":_vm.headers,"items":_vm.blockResults,"search":_vm.search,"sort-by":"transactionIndex"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Input: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.input)+" ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
												var on = ref.on;
												var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"three-line":"","to":/transaction/ +
														item.hash}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Hash: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.hash)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Go to transaction")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 1st 32 bytes: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.r)+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Next 32 bytes: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.s)+" ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Recovery Value: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.v)+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Nonce: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.nonce)+" ")])],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"error","top":"","right":"","multi-line":"","timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
														var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarError)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }