var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"persistent":"","width":"434"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{staticClass:"pa-5 text-center",attrs:{"color":"primary"}},[_c('v-card-title',{staticClass:"white--text justify-center"},[_vm._v(" Loading: Please Wait... ")]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"color":"white","width":"7","size":"70","indeterminate":""}})],1)],1)],1)],1),(_vm.transaction.length !== 0)?_c('v-card',[_c('v-card-title',[_vm._v(" Transaction for hash "+_vm._s(_vm.$route.params.id)+" ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.transaction),function(value,name,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.transactionHeaders[ index ])+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(value)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltips[index]))])])],1)}),1)],1),(_vm.transaction.isContract)?_c('v-divider',{staticClass:"my-5"}):_vm._e(),(_vm.transaction.isContract)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"display-2 font-weight-light justify-center"},[_vm._v(" Decompilation Data ")]),_c('v-card-text',{staticClass:"py-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-center py-3"},[_vm._v("Events")]),_vm._v(" "+_vm._s(_vm.contractEvents)+" ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-center py-3"},[_vm._v("Functions")]),_vm._v(" "+_vm._s(_vm.contractFunctions)+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-center py-3"},[_vm._v(" Full Solidity Code ")]),_c('v-textarea',{attrs:{"value":_vm.decompiledContract,"rows":"20"},on:{"click":function($event){return _vm.copyToClipboard(_vm.decompiledContract)}}})],1)],1)],1)],1):_vm._e(),(_vm.transaction.isContract)?_c('v-divider',{staticClass:"my-5"}):_vm._e(),(_vm.hasError)?_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" There was an error ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.error)+" ")])],1):_vm._e(),(_vm.transaction.isContract)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-center justify-center display-2 font-weight-light"},[_vm._v(" Contract binary "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.contractBinary)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,3555262052)},[_c('span',[_vm._v("Copy Binary Code")])])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.contractBinary)+" ")])],1):_vm._e()],1):_c('div',{staticClass:"text-center display-3"},[_vm._v(" Something went wrong.Try refreshing and if nothing happens please contact admin. ")])],1)],1),_c('v-snackbar',{attrs:{"color":_vm.color,"top":"","right":"","multi-line":"","timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }